import React from 'react';
import './Products.css';
import { Extra } from '../../common/types';

export const ProductExtra = (props: { extra: Extra }) => {
    const numberFormat = (value: number) =>
        new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR'
        }).format(value);

    const getPriceString = (collection: number[]) =>
        collection.map((price) => numberFormat(price)).join(' / ');
    
    return(
        <>
            <p className='product-price'>+ { getPriceString(props.extra.price) }</p>
            <p className='product-name'>{ props.extra.name }</p>
        </>
    )
}
