import React, { useEffect, useState } from 'react';
import { Allergen, Product } from '../../common/types';
import { ProductLine } from './ProductLine';
import data from "../../data/allergens.json";
import './Products.css';

export const ProductsList = (props: { list: Product[] }) => {
    const [allergens, setAllergens] = useState<Allergen[]>([]);

    useEffect(() => {
        setAllergens(data.allergens);
    }, []);

    return(
        <>
        {props.list.map((product) => (
            <div key={ product.name } className="product-line">
                <ProductLine product={ product }></ProductLine>

                <p className='product-allergens'>{
                    product.allergens.map(allergen => {
                        const allergen_data = allergens.find((item) => item.key === allergen);

                        return (<img
                            key={ allergen }
                            className='product-allergens-icon'
                            src={ '/img/allergens/' + allergen_data?.icon }
                            alt={ allergen_data?.name }
                            title={ allergen_data?.name }
                        ></img>);
                })
                }</p>
            </div>
        ))}
        </>
    )
}
