import React from 'react';
import './Contact.css';

export const Contact = () => {
    const phone_number: string = "926 21 29 55";
    const address: string = "Parque de Gasset. 13001. Ciudad Real";

    return (
        <footer className='footer'>
            <div className='contact'>
                { address }<br/>
                Teléfono <a className='global-link' href={'tel:' + phone_number.replaceAll(' ', '') }>{ phone_number }</a>
            </div>

            <div className='made-with-love'>
                Hecho con ❤️ por <a href="https://github.com/javiBertos" className='global-link' target="_blank" rel="noopener noreferrer">javiBertos</a>
            </div>
        </footer>
    );
}
