import React from 'react';
import { Link } from 'react-router-dom';

export const Home = () => {
    return (
        <div className='text-center'>
            <h1>Terraza Los Candiles</h1>

            <p>Bienvenido a nuestra web, aunque estamos preparando el contenido, pero de momento, te invitamos a consultar <Link to="/carta" className='global-link'>nuestra carta</Link>.</p>

            <p>Te recordamos que puedes reservar tu mesa llamando al <a className='global-link' href="tel:685345141">685 34 51 41</a> en el siguiente horario</p>

            <p>
                Mañanas de <b>10h a 13h</b><br />
                Tardes de <b>18h a 21h</b>
            </p>
        </div>
    );
}

