import React from 'react';
import './Services.css';
import { ServiceLine } from './ServiceLine';
import { Service } from '../../common/types';

export const ServicesList = (props: { list: Service[] }) => {
    return(
        <>
        {props.list.map((service) => (
            <div key={ service.key } className="service-line">
                <ServiceLine service={ service }></ServiceLine>
            </div>
        ))}
        </>
    )
}
