import React from 'react';
import { Product } from '../../common/types';
import './Products.css';

export const ProductLine = (props: { product: Product }) => {
    const numberFormat = (value: number) =>
        new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR'
        }).format(value);

    const getPriceString = (collection: number[]) =>
        collection.map((price) => numberFormat(price)).join(' / ');
    
    return(
        <>
            <p className='product-price'>{ getPriceString(props.product.price) }</p>
            <p className='product-name'>{ props.product.name }</p>
            <p className='product-ingredients'>{ props.product.ingredients.join(', ') }</p>
        </>
    )
}
