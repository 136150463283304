import React from 'react';
import './Reset.css';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Contact } from './components/';
import { Home, Menu, Error404 } from './pages/';

export const App = () => {
  const router =  createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <Error404 />
    },
    {
      path: "/carta",
      element: <Menu />
    },
  ]);

  return (
    <div className="main">
      <header>
        <h1 className='main-title'>
          <a href="/" className='main-title-link'>
            <img src={'/img/los-candiles-logo-no-bg.png'} className="logo" alt="Los Candiles [logo]" />
            <span className='main-title-text'>Terraza Los Candiles Ciudad Real</span>
          </a>
        </h1>
      </header>
      
      <RouterProvider router={ router } />

      <Contact />
    </div>
  );
}
