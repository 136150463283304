import React from 'react';
import './Services.css';
import { Service } from '../../common/types';

export const ServiceLine = (props: { service: Service }) => {
    const numberFormat = (value: number) =>
        new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR'
        }).format(value);

    const getPriceString = (collection: number[]) =>
        collection.map((price) => numberFormat(price)).join(' / ');
    
    return(
        <>
            <p className='service-price'>+ { getPriceString(props.service.price) }</p>
            <p className='service-name'>{ props.service.name }</p>
        </>
    )
}
