import React, { useEffect, useState } from 'react';
import { Allergen } from '../../common/types';
import data from "../../data/allergens.json";
import './Allergens.css';

export const Allergens = () => {
    const [allergens, setAllergens] = useState<Allergen[]>([]);

    useEffect(() => {
        setAllergens(data.allergens);
    }, []);

    return(
        <div className="main-allergens">{
            allergens.map(allergen =>(<p key={ allergen.key } className='main-allergens-item'>
                <img
                    className='main-allergens-item-icon'
                    src={ '/img/allergens/' + allergen.icon }
                    alt={ allergen.name }
                    title={ allergen.name }
                ></img>
                <span className='main-allergens-item-name'>{ allergen.name }</span>
            </p>))
        }</div>
    )
}
