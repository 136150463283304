import React from 'react';
import { useRouteError } from 'react-router-dom';

export const Error404 = () => {
    const error: any = useRouteError();

    console.warn(error);

    return (
        <div>
            <h1>Oops!</h1>

            <p>Sorry, an unexpected error has occurred.</p>
            <p><i>{ error.statusText || error.message }</i></p>
        </div>
    );
}
