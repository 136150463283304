import React, { useEffect, useState } from 'react';
import { Section, Service } from '../../common/types';
import menu_data from "../../data/menu.json";
import services_data from "../../data/services.json";
import { Allergens, MenuSection, ServicesList } from '../../components';
import './Menu.css';

export const Menu = () => {
    const [menu_sections, setMenuSections] = useState<Section[]>([]);
    const [services_list, setServicesList] = useState<Service[]>([]);

    useEffect(() => {
        setMenuSections(menu_data.sections);
        setServicesList(services_data.services);
    }, []);

    return(
        <div className='menu'>
            {menu_sections.map((section) => (
                <MenuSection key={ section.key } section={ section } />
            ))}
            
            <ServicesList list={ services_list } />

            <div className='vat-status'>I.V.A. incluído</div>

            <Allergens />
        </div>
    )
}
